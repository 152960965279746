import React from "react"
import MainLayout from "../../layouts"
import { Col, Container, Row } from "react-bootstrap"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import ContactHeading from "../../components/ContactHeading"
import ServiceCards from "../../ServiceCards"

export const pageQuery = graphql`
  query {
    ardenArcadeImage: file(relativePath: { eq: "arden-arcade.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithRekeyingLockImage: file(
      relativePath: { eq: "locksmith-rekeying-lock.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 265, maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    leoInCarImage: file(relativePath: { eq: "leo-in-car.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 265, maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    leoInVanWorkingImage: file(relativePath: { eq: "leo-in-van-working.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 265, maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithVanInCityImage: file(
      relativePath: { eq: "locksmith-van-in-city.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 265, maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

function ArdenArcadePage({ data }) {
  return (
    <MainLayout
      title="24/7 Cheap Locksmith Services Arden-Arcade | (916) 995-0135"
      description="Lock rekeying, lock installations, access control & more. Your local trusted auto, residential, and commercial locksmith."
      offset
    >
      <ContactHeading background={data.ardenArcadeImage.childImageSharp.fluid}>
        <h1 className="text-center">Arden Arcade Locksmith</h1>
        <h2 className="text-center font-weight-light ">
          24 Hour Arden Arcade Locksmith
        </h2>
      </ContactHeading>
      <section>
        <ServiceCards />
      </section>
      <Container as="section">
        <h2 className="text-center">Arden Arcade's Leading Locksmith</h2>
        <p className="indent mb-0">
          Swift Locksmith is a licensed, local and trusted family business that
          has been serving the Arden Arcade community and the surrounding area
          for over 10 years. Our customers always come first, so no matter what
          locksmith services you are looking for, emergency or non-emergency, we
          are available day or night to assist you, seven days a week. Our team
          has been in the locksmith service industry for a long time and their
          expertise and knowledge about their work will guarantee that you are
          in safe hands. We offer exceptional residential, commercial and
          automotive locksmith services that vary from a basic lockout service
          to installing a high security system for your home or office. Swift
          locksmith is Arden Arcade’s trusted locksmith so give us a call today
          for your locksmith needs!
        </p>
      </Container>
      <Container as="section">
        <Row className="justify-content-center">
          <Col
            xs={12}
            md={5}
            className="mb-5 d-flex flex-column justify-content-between col-sm-pad"
          >
            <div className="ml-sm-5 ml-md-0">
              <h2 className="mb-2 mx-3 mx-sm-0">Residential Services</h2>
              <ul>
                <li>House, Bedroom Lock Out Services</li>
                <li>Lock Repair Services</li>
                <li>Door Knob Lock Installation</li>
                <li>Duplicate House Keys</li>
                <li>High Security Locks</li>
                <li>Master Key System Installation</li>
                <li>Door Lock Rekey Services</li>
                <li>Lock Repair Services</li>
              </ul>
            </div>
            <Img
              fluid={data.locksmithRekeyingLockImage.childImageSharp.fluid}
              alt="locksmith-rekeying-lock"
              className="mx-auto w-100"
              style={{ maxHeight: 265, maxWidth: 400 }}
            />
          </Col>
          <Col
            xs={12}
            md={5}
            className="mb-5 d-flex flex-column justify-content-between col-sm-pad"
          >
            <div className="ml-sm-5 ml-md-0">
              <h2 className="mb-2 mx-3 mx-sm-0">Automotive Services</h2>
              <ul>
                <li>Vehicle Lock Outs</li>
                <li>Broken Key Extraction</li>
                <li>Ignition Rekey</li>
                <li>Duplicate Car Keys</li>
                <li>Chip Keys Cut Services</li>
                <li>Transponder Keys Programmed</li>
                <li>Lost Car Key Replacement</li>
                <li>Replace Keyless Entry Remotes</li>
              </ul>
            </div>
            <Img
              fluid={data.leoInCarImage.childImageSharp.fluid}
              alt="leo-in-car"
              className="mx-auto w-100"
              style={{ maxHeight: 265, maxWidth: 400 }}
            />
          </Col>
          <Col
            xs={12}
            md={5}
            className="mb-5 mb-md-0 d-flex flex-column justify-content-between col-sm-pad"
          >
            <div className="ml-sm-5 ml-md-0">
              <h2 className="mx-3 mx-sm-0">Commercial Services</h2>
              <ul>
                <li>Business Rekey</li>
                <li>Lock Repairs</li>
                <li>Replace Lost Keys</li>
                <li>Master Key system Installation</li>
                <li>Keyless Access System</li>
                <li>High Security & Standard Deadbolts</li>
                <li>Key Entry Knobs</li>
                <li>Office Lock Out Service</li>
              </ul>
            </div>
            <Img
              fluid={data.leoInVanWorkingImage.childImageSharp.fluid}
              alt="leo-in-van-working"
              className="mx-auto w-100"
              style={{ maxHeight: 265, maxWidth: 400 }}
            />
          </Col>
          <Col
            xs={12}
            md={5}
            className="d-flex flex-column justify-content-between col-sm-pad"
          >
            <div className="ml-sm-5 ml-md-0">
              <h2 className="mx-3 mx-sm-0">Emergency Services</h2>
              <ul>
                <li>Car, House, Office Lockouts</li>
                <li>Bedroom, Storage, Garage Lockouts</li>
                <li>Emergency Rekey Services</li>
                <li>Emergency Lock Repair Services</li>
                <li>Broken Key Extraction</li>
                <li>Replacement of Lost Car Keys</li>
                <li>Lock Picking</li>
                <li>Mail Box Lockouts</li>
              </ul>
            </div>
            <Img
              fluid={data.locksmithVanInCityImage.childImageSharp.fluid}
              alt="locksmith-in-van-city"
              className="mx-auto w-100"
              style={{ maxHeight: 265, maxWidth: 400 }}
            />
          </Col>
        </Row>
      </Container>
      <Container as="section" className="mb-5">
        <h2>Why Choose Arden Arcade Swift Locksmith</h2>
        <p className="indent">
          If you are located in the Arden Arcade area and are faced with a car,
          home or business locksmith problem, Swift Locksmith professionals are
          always here to help you. Our technicians are working around the clock
          to help people like you every single day and we try to resolve every
          locksmith need quickly and efficiently. We understand the
          inconvenience of having to drive to a locksmith shop or the hassle of
          scheduling an appointment for a locksmith service. Our mobile vans are
          equipped with all the proper tools, so we bring the locksmith shop and
          necessary tools to you.
        </p>
        <p className="indent mb-0">
          Our locksmith company is built on a reputation of honesty,
          professionalism and exceptional customer service because we always put
          our customers first. If you are looking for reliable locksmith
          services, quality materials and affordable prices, call Swift
          Locksmith today and we will be happy to help you!
        </p>
      </Container>
    </MainLayout>
  )
}

export default ArdenArcadePage
